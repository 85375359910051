<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <!-- <van-swipe :autoplay="3000" style="height:120px;width:100%">
      <van-swipe-item v-for="(item, index) in images" :key="index">
        <img
          :src="item.imageUrl"
          style="width:100%;height:100%"
          @click="$router.push(item.urlPath)"
        />
      </van-swipe-item>
    </van-swipe> -->

    <van-tabs v-model="activeName" sticky @click="tabClick">
      <van-tab title="线下课程" name="b">
        <div class="tabcontent">
          <CourseOfflineList></CourseOfflineList>
        </div>
      </van-tab>
      <van-tab title="线上课程" name="a">
        <div class="tabcontent">
          <CourselineList></CourselineList>
        </div>
      </van-tab>

      <van-tab title="制度须知" name="c">
        <van-row>
          <van-col span="24">
            <ArticleList :type="111"></ArticleList>
          </van-col>
        </van-row>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getSchoolListAPI } from "../../api/seting";
import CourselineList from "./CourselineList";
import CourseOfflineList from "./courseofflineList";
import ArticleList from "../components/articleList";
import merge from "webpack-merge";
//const key = "zn-history";
export default {
  name: "s7strategy",
  data() {
    return {
      scroll: 0,
      activeName: "b",
      title: "课程中心",
      body: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [206],
          // label: "讲师 轮播图",
        },
        // value: 208,
      },
      images: [],
    };
  },
  components: {
    CourselineList,
    CourseOfflineList,
    ArticleList,
  },
  activated() {},
  created() {
    this.initData();
    this.initswipers();
  },
  updated() {
    //this.showQW();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/course/list") {
        if (to.query != from.query) {
          this.initData();
        }
      }
    },
  },

  methods: {
    async initswipers() {
      let result = await getSchoolListAPI(this.body);
      let { data } = result.data;
      this.images = data;
    },
    async initData() {
      document.title=this.title;
      let type = this.$route.query.type;
      if (type != undefined) {
        this.activeName = type;
      }
    },
    toadd() {
      this.$router.push({ path: "/course/add" });
    },
    tabClick() {
      this.$router.push({
        query: merge({}, { id: undefined }),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 30px;
}
.container {
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;

  /deep/.van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.tabcontent {
  height: 100%;
  margin-bottom: 138px;
}

.acontent {
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
