<template>
  <div class="container" ref="wrapper">
    <van-search
      shape="round"
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <van-dropdown-menu>
      <van-dropdown-item title="分类" ref="item">
        <van-tree-select
          :height="450"
          :items="ccitems"
          :active-id.sync="activeId"
          :main-active-index.sync="activeIndex"
          @click-item="onitemclick"
          @click-nav="onitemnavclick"
        />
      </van-dropdown-item>
      <van-dropdown-item
        :options="option"
        v-model="value"
        @change="changeSorterType(value)"
      />
      <van-dropdown-item title="筛选" ref="itemRef">
        <van-cell center title="进阶S7">
          <template #right-icon>
            <van-switch v-model="page.filterData.isS7" size="24" active-color="#ee0a24" />
          </template>
        </van-cell>
        <div style="padding: 5px 16px">
          <van-button type="danger" block round @click="changeIsS7(value)">
            确认
          </van-button>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell
            @click="toDetail(item.id.toString())"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <van-col span="8"
              ><van-image
                lazy-load
                class="w100"
                fit="contain"
                style="height: 120px; width: 110px"
                :src="item.imageUrl"
              />
            </van-col>
            <van-col span="16" align="bottom" style="padding-left: 20px">
              <div
                style="display: table-cell; vertical-align: middle"
                class="zntitle van-multi-ellipsis--l3"
              >
                <h3 style="display: inline-block">
                  {{ item.title }}
                </h3>
              </div>
              <div v-if="item.isS7">
                <van-tag
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >进阶S7
                </van-tag>
              </div>
              <div
                v-if="item.browsingTime != undefined"
                style="float: left; color: #c7861b; font-weight: bold"
              >
                {{
                  item.browsingTime != undefined ? getbrowsingTimeStr(item) : ""
                }}
              </div>
              <div style="float: right">{{ item.creationTime }}</div>
              <div style="float: right; margin-right: 13px">
                浏览{{ item.readCount }}人次
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getCourseList, getClassWithChildren } from "@/api/school";

//const key = "zn-history";
export default {
  name: "zncourselist",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      ccitems: [],
      activeId: 1,
      activeIndex: 0,

      pclassvalue: 0,

      pclassOption: [{ text: "全部", value: 0 }],

      type: 100,
      title: "中酿学院",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        fromType: 1,
        filterData: {
          title: "",
          schoolClass: 0,
          schoolClass2: 0,
          typeId: 0,
          isS7: false,
        },
      },
      option: [
        { text: "默认", value: 0 },
        { text: "最热", value: 3 },
        { text: "最新", value: 1 },
        { text: "最早", value: 2 },
      ],
      value: 0,
      option1: [
        { text: "进阶S7", value1: 0 },
        // { text: "进阶S71", value1: 1 },
      ],
      value1: 0,
      switch1: false,
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
    this.onSearch();
  },
  watch: {
    // $route(to, from) {
    //   if (to.path != "/course/detail" && from.path != "/course/detail") {
    //     //监听路由是否变化
    //     if (to.query != from.query) {
    //       this.initData();
    //       this.onSearch();
    //     }
    //   }
    // },
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      if (this.$route.query.id != undefined) {
        this.page.filterData.schoolClass = this.$route.query.id;
      } else {
        this.page.filterData.schoolClass = 0;
      }
      if (this.$route.query.id2 != undefined) {
        this.page.filterData.schoolClass2 = this.$route.query.id2;
      } else {
        this.page.filterData.schoolClass2 = 0;
      }
      let cresult = await getClassWithChildren({ fromType: 1 });
      this.ccitems = [];
      cresult.data.data.forEach((citem, cindex) => {
        let chilrenitem = [];
          //根据参数设置选择的分类
        if (citem.id == this.page.filterData.schoolClass) {
          this.activeIndex = cindex;
        }
        citem.children.forEach((chitem) => {
          if (chitem.count > 0) {
            //根据参数设置选择的分类
          if (chitem.id == this.page.filterData.schoolClass2) {
            this.activeIndex = cindex;
            this.activeId = chitem.id;
          }
            chilrenitem.push({
              text: chitem.name+"("+chitem.count+"条)",
              id: chitem.id,
            });
          } 
        });
        if (citem.count > 0) {
          this.ccitems.push({
            text: citem.name+ "(" + citem.count + "条)",
            id: citem.id,
            children: chilrenitem,
          });
        } else {
          this.ccitems.push({
            text: citem.name,
            id: citem.id,
            children: chilrenitem,
          });
        }
      });
    },
    onitemclick(data) {
      this.$refs.item.toggle();

      if (data.text.indexOf("全部") >= 0) {
        //全部
        this.page.filterData.schoolClass = data.id;
        this.page.filterData.schoolClass2 = 0;
      } else {
        this.page.filterData.schoolClass = 0;
        this.page.filterData.schoolClass2 = data.id;
      }
      this.onSearch();
      //this.$router.push({path:'/course/list?cid'+data.id})
    },
    onitemnavclick(index) {
      if (this.ccitems[index].children.length == 0) {
        this.$refs.item.toggle();

        this.page.filterData.schoolClass = this.ccitems[index].id;
        this.page.filterData.schoolClass2 = 0;
        this.onSearch();

        //this.$router.push({path:'/course/list?cid='+this.ccitems[index].id})
      }
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
    changeIsS7() {
      // if (this.page.filterData.isS7 === false) {
      //   this.page.filterData.isS7 = true;
      // } else {
      //   this.page.filterData.isS7 = false;
      // }
      this.onSearch();
      this.$refs.itemRef.toggle();
    },
    closeisS7() {
      this.page.filterData.isS7 = undefined;
      this.onSearch();
    },
    toDetail(id) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;
      this.$router.push({
        path: "/course/detail",
        query: { para: id, type: this.page.filterData.productClass },
      });
    },

    getbrowsingTimeStr(item) {
      if(item.browsingTime.part&&item.browsingTime.part!="")
      {
        return "已学"+item.browsingTime.part+"章节";
      }
      if (item.browsingTime.percent >= 0.01) {
        if (item.browsingTime.percent == 1) {
          return "已学完";
        } else {
          return "已学" + (item.browsingTime.percent * 100).toFixed(0) + "%";
        }
      } else {
        return ""; //"未学习"
      }
    },

    async onLoad() {
      this.upLoading = true;

      let aresult = await getCourseList({ ...this.page });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
// .w100 {
//   /deep/ img {
//     width: 100%;
//     max-height: 200px;
//   }
// }
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 45px;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
