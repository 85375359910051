<template>
  <div class="container">
    <CourseofflineItemList
      :Type="1"
      :toDetail="toDetail"
    ></CourseofflineItemList>
  </div>
</template>

<script>
import CourseofflineItemList from "../components/CourseofflineItemList";

export default {
  data() {
    return {};
  },
  components: { CourseofflineItemList },
  created() {},
  watch: {},
  mounted() {},

  methods: {
    toDetail(item) {
      this.$router.push({
        path: "/course/courseofflinedetail",
        query: { para: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
